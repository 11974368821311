module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-131785324-1","anonymize":true,"head":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"frederic-pun-personal","short_name":"fpunny-site","start_url":"/","background_color":"#ffffff","theme_color":"#009cda","display":"minimal-ui","icon":"src/images/logo.svg","icon_options":{"purpose":"maskable"},"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"e5aa86e8c2dbe29266360ef61b817730"},
    }]
